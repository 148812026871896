import type { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import Head from 'next/head'
import Layout from 'src/layouts'

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Error 404 Page Not Found! - Dreo</title>
      </Head>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          component="h1"
          align="center"
          sx={{
            width: '100%',
            fontSize: {
              lg: '84px',
              md: '60px',
              xs: '50px',
            },
            lineHeight: 1.25,
            color: 'grey.20',
            fontWeight: 'bold',
          }}
        >
          Oooops!
        </Typography>
        <Typography
          component="h3"
          align="center"
          sx={{
            width: '100%',
            fontSize: {
              lg: '36px',
              md: '30px',
              xs: '26px',
            },
            color: 'grey.80',
            fontWeight: 'bold',
          }}
        >
          Something went wrong
        </Typography>
        <Typography
          component="p"
          align="center"
          sx={{
            width: '100%',
            fontSize: {
              lg: '24px',
              md: '20px',
              xs: '15px',
            },
            color: 'gray.80',
          }}
        >
          Error 404 Page Not Found!
        </Typography>
      </Box>
    </>
  )
}

Custom404.getLayout = (component: ReactElement) => {
  return <Layout>{component}</Layout>
}
